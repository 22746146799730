const microApps = [
  {
    name: 'dashboard',
    subName: 'VUE_APP_dashboarD_APP',
    entry: null,
    activeRule: '/dashboard'
  },
  {
    name: 'TDS Challans',
    subName: 'VUE_APP_tdS_CHALLANS_APP',
    entry: null,
    activeRule: '/tds/challans'
  },
  {
    name: 'TDS Compliances',
    subName: 'VUE_APP_tdS_COMPLIANCES_APP',
    entry: null,
    activeRule: '/tds/compliances'
  },
  {
    name: 'TDS Liability',
    subName: 'VUE_APP_tdS_LIABILITY_APP',
    entry: null,
    activeRule: '/tds/liability'
  },
  {
    name: 'TDS Masters',
    subName: 'VUE_APP_tdS_MASTERS_APP',
    entry: null,
    activeRule: '/tds/masters'
  },
  {
    name: 'TDS Reports',
    subName: 'VUE_APP_tdS_REPORTS_APP',
    entry: null,
    activeRule: '/tds/reports'
  },
  {
    name: 'TDS Returns',
    subName: 'VUE_APP_tdS_RETURNS_APP',
    entry: null,
    activeRule: '/tds/returns'
  },
  {
    name: 'TDS Administration',
    subName: 'VUE_APP_tdS_ADMINISTRATION_APP',
    entry: null,
    activeRule: '/administration'
  },
  {
    name: 'TDS User Roles',
    subName: 'VUE_APP_tdS_USER_ROLES_APP',
    entry: null,
    activeRule: '/tds/userroles'
  },
  {
    name: 'TCS Challans',
    subName: 'VUE_APP_tcS_CHALLANS_APP',
    entry: null,
    activeRule: '/tcs/challans'
  },
  {
    name: 'TCS Liability',
    subName: 'VUE_APP_tcS_LIABILITY_APP',
    entry: null,
    activeRule: '/tcs/liability'
  },
  {
    name: 'TCS Masters',
    subName: 'VUE_APP_tcS_MASTERS_APP',
    entry: null,
    activeRule: '/tcs/masters'
  },
  {
    name: 'Form26AS Masters',
    subName: 'VUE_APP_forM26AS_MASTERS_APP',
    entry: null,
    activeRule: '/form26as/masters'
  },
  {
    name: 'Form26AS Reconcilation',
    subName: 'VUE_APP_forM26AS_RECON_APP',
    entry: null,
    activeRule: '/form26as/reconcilation'
  },
  {
    name: 'Form26AS Reports',
    subName: 'VUE_APP_forM26AS_REPORTS_APP',
    entry: null,
    activeRule: '/form26as/reports'
  },
  {
    name: 'Clause34 Reconcilation',
    subName: 'VUE_APP_clause34_Recon_APP',
    entry: null,
    activeRule: '/clause34/reconcilation'
  },
  {
    name: 'TDS Masters Clause34',
    subName: 'VUE_APP_tdS_MASTERS_APP',
    entry: null,
    activeRule: '/tds/masters/clause34'
  },
  {
    name: 'Clause34 Review',
    subName: 'VUE_APP_clause34_Review_APP',
    entry: null,
    activeRule: '/clause34/review'
  },
  {
    name: 'Clause34 Reports',
    subName: 'VUE_APP_clause34_Reports_APP',
    entry: null,
    activeRule: '/clause34/reports'
  },
  {
    name: 'Clause34 Document Repo',
    subName: 'VUE_APP_clause34_DocRepo_APP',
    entry: null,
    activeRule: '/clause34/docrepo'
  },
  // super admin apps
  {
    name: 'Onboarding',
    subName: 'VUE_APP_client_Onboarding_APP',
    entry: null,
    activeRule: '/onboarding'
  },
  {
    name: 'Masters',
    subName: 'VUE_APP_superadmin_Masters_APP',
    entry: null,
    activeRule: '/masters',
    active: true
  },
  {
    name: 'User Roles',
    subName: 'VUE_APP_user_Roles_APP',
    entry: null,
    activeRule: '/userroles',
    active: true
  }
]

const apps = microApps.map((item) => {
  item.entry = (process.env[item.subName]) ? process.env[item.subName] : item.entry
  return {
    ...item,
    container: '#subapp-viewport',
    props: {
      routerBase: item.activeRule
    }
  }
})

export default apps
