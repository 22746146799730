<template>
  <div v-if="$oidc.isAuthenticated" class="layout-wrapper">
    <a-layout-header class="header">
      <div class="logo">
        <MenuOutlined class="trigger" @click="() => (collapsed = !collapsed)" />
        <img class="ml-3 mr-2" :src="`${publicPath}images/Deloitte-logo_white.jpg`" alt="logo" srcset=""> <span
          class="vertical-line">|</span>
        <span class="home-title ml-3 mr-4">TechTDS</span>
      </div>
      <a-menu v-if="showHeader && currentUserRole != 1" theme="dark" mode="horizontal" :selectedKeys="[selectedHeader]">
        <template v-for="header in headers">
          <!-- <a-menu-item v-if="header.subName === 'dashboard' && currentUserRole !== '1'" :key="header.name"
            :class="{ 'ant-menu-item-selected': header.subName === selectedHeader }" @click="gotoHeader(header)">{{
              header.name }}</a-menu-item> -->
          <a-menu-item v-if="userModules?.indexOf(header.masterName) > -1 && header.subName !== 'dashboard'" :key="header.subName" @click="gotoHeader(header)">{{
              header.name }}</a-menu-item>
        </template>
      </a-menu>
      <div class="notifi-block">
        <template v-if="currentUserRoles.length > 1">
          <a-select ref="select" :value="currentUserRole" style="width: 120px" @focus="focus" @change="handleChange">
            <a-select-option v-for="role in currentUserRoles" :key="role.RoleId" :value="role.RoleId">{{ role.RoleName
            }}</a-select-option>
          </a-select>
        </template>
        <!-- <div class="notifi-sub" @click="showModal">
          <img :src="`${publicPath}images/notifications.svg`" alt="icon" />
          <sup class="notifi-count">2</sup>
        </div>
        <a-modal class="notifi-modal modal-no-header modal-no-footer" :visible="visible" title="Basic Modal"
          @ok="handleOk" @cancel="handleCancel">
          <small class="d-block heading">Notifications</small>
          <ul>
            <li>
              <span class="noti-avatar">DN</span>
              <div class="noti-text">
                <h6>Jhanvi Singh <span class="rejected">Rejected</span> approval request of file
                  <strong>>Tax_verification.xlsx</strong> </h6>
                <small>Today at 9:42 PM</small>
              </div>
            </li>
            <li>
              <span class="noti-avatar">DN</span>
              <div class="noti-text">
                <h6><strong>Ray Arnold</strong> left 6 comments on <strong>Isla Nublar SOC2 compliance report</strong>
                </h6>
                <small>Yesterday at 11:42 PM</small>
              </div>
            </li>
          </ul>
        </a-modal> -->
        <a-dropdown :trigger="['click']" :style="{ display: 'inline-block' }">
          <a class="ant-dropdown-link" @click.prevent :style="{ color: '#fff' }">
            <a-row>
              <a-col :span="7" class="text-right m-auto">
                <span class="user-avatar">
                  <!-- <img :src="`${publicPath}images/Avatar.png`" alt="user" /> -->
                  <span class="user-avatar">{{ getThumnbailUsername(loggedinUserInfo?.userName) }}</span>
                  <!-- <sub class="user-active"></sub> -->
                </span>
              </a-col>
              <a-col :span="15" class="m-auto">
                <span>{{ loggedinUserInfo?.userName }}</span>
                <small v-if="loggedinUserInfo?.tenantName">{{ loggedinUserInfo?.tenantName }}</small>
                <DownOutlined class="profile-down-icon"/>
              </a-col>
            </a-row>
          </a>
          <template overlayClassName="myclass" #overlay :style="{ width: '250px', right: '0' }">
            <a-menu class="logout-dropdown">
              <a-menu-item key="3" @click="logout()">
                <span class="logout-main">
                  <span class="logout-left">Logout</span>
                  <span class="logout-right material-symbols-outlined">
                    logout
                  </span>
                </span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </a-layout-header>
    <a-layout-content v-if="showSidenav == 'no'" style="min-height: 100vh;padding-top:48px">
      <a-layout class="main-section m-l">
        <a-layout-content :style="{ minHeight: '280px' }">
          <div id="subapp-viewport"></div>
        </a-layout-content>
      </a-layout>
    </a-layout-content>
    <a-layout v-if="showSidenav == 'yes'" style="min-height: 100vh;padding-top:48px">
      <a-layout-sider :collapsed="collapsed" :trigger="null" collapsible>
        <!-- <small class="nav-heading">Main</small> -->
        <a-menu v-if="gotPermissions" theme="dark" mode="inline" :openKeys="openKeys" :selectedKeys="selectedKeys"
          @openChange="onOpenChange">
          <template v-for="menu in sidenavMenu">
            <template v-if="menu.active">
              <template v-if="!menu.children">
                <a-menu-item v-if="loggedinUserPermissions?.indexOf(menu.permission) > -1" :key="menu.subName"
                  @click="goto(menu)">
                  <!-- <template #icon><img :src="`${publicPath}${menu.iconPath}`"></template> -->
                  <template #icon>
                    <span v-if="menu.subName === 'masters'" class="material-symbols-outlined">database</span>
                    <span v-if="menu.subName === 'liability'" class="material-symbols-outlined">description</span>
                    <span v-if="menu.subName === 'challans'" class="material-symbols-outlined">list</span>
                    <span v-if="menu.subName === 'compliances'" class="material-symbols-outlined">verified_user</span>
                    <span v-if="menu.subName === 'reports'" class="material-symbols-outlined">article</span>
                    <span v-if="menu.subName === 'document'" class="material-symbols-outlined">assignment</span>
                    <span v-if="menu.subName === 'settings'" class="material-symbols-outlined">settings</span>
                    <span v-if="menu.subName === 'administration'" class="material-symbols-outlined">account_circle</span>
                    <span v-if="menu.subName === 'returns'" class="material-symbols-outlined">description</span>
                    <span v-if="menu.subName === 'reconcilation'" class="material-symbols-outlined">note</span>
                    <span v-if="menu.subName === 'review'" class="material-symbols-outlined">description</span>
                    <span v-if="menu.subName === 'docrepo'" class="material-symbols-outlined">assignment</span>
                    <span v-if="menu.subName === 'onboarding'" class="material-symbols-outlined">how_to_reg</span>
                  </template>
                  {{ menu.name }}
                </a-menu-item>
              </template>
              <template v-else>
                <a-sub-menu v-if="loggedinUserPermissions?.indexOf(menu.permission) > -1" :key="menu.subName">
                  <!-- <template #icon><img :src="`${publicPath}${menu.iconPath}`"></template> -->
                  <template #icon>
                    <span v-if="menu.subName === 'masters'" class="material-symbols-outlined">database</span>
                    <span v-if="menu.subName === 'liability'" class="material-symbols-outlined">description</span>
                    <span v-if="menu.subName === 'challans'" class="material-symbols-outlined">list</span>
                    <span v-if="menu.subName === 'compliances'" class="material-symbols-outlined">verified_user</span>
                    <span v-if="menu.subName === 'reports'" class="material-symbols-outlined">article</span>
                    <span v-if="menu.subName === 'document'" class="material-symbols-outlined">assignment</span>
                    <span v-if="menu.subName === 'settings'" class="material-symbols-outlined">settings</span>
                    <span v-if="menu.subName === 'administration'" class="material-symbols-outlined">account_circle</span>
                    <span v-if="menu.subName === 'returns'" class="material-symbols-outlined">description</span>
                    <span v-if="menu.subName === 'reconcilation'" class="material-symbols-outlined">note</span>
                    <span v-if="menu.subName === 'review'" class="material-symbols-outlined">description</span>
                    <span v-if="menu.subName === 'docrepo'" class="material-symbols-outlined">assignment</span>
                    <span v-if="menu.subName === 'onboarding'" class="material-symbols-outlined">how_to_reg</span>
                  </template>
                  <template #title>{{ menu.name }}</template>
                  <template v-for="subMenu in menu.children">
                    <template v-if="subMenu.isLabel">
                      <a-menu-item class="menu-label" :key="subMenu.subName">
                        {{ subMenu.name }}
                      </a-menu-item>
                    </template>
                    <template v-else>
                      <template v-if="loggedinUserPermissions?.indexOf(subMenu.permission) > -1">
                        <a-menu-item :key="subMenu.subName" @click="gotoSubMenu(subMenu)">
                          {{ subMenu.name }}
                        </a-menu-item>
                      </template>
                    </template>
                  </template>
                </a-sub-menu>
              </template>
            </template>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout class="main-section">
        <a-layout-content :style="{ minHeight: '280px' }">
          <div id="subapp-viewport"></div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-layout-footer v-if="env === 'UAT'" class="text-right">
      <!-- OneTrust Cookies Settings button start -->
      <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
      <!-- OneTrust Cookies Settings button end -->
    </a-layout-footer>
    <!-- <ModalIdle v-if="isIdle" /> -->
  </div>
  <router-view />
  <a-modal style="top: 20px" :visible="policyModal" :closable="false" width="1200px" title="Privacy Statement" @ok="handlepPlicyModalOk">
    <template #footer>
        <a-button key="back" type="primary" @click="handleAgree" :loading="agreeClickLoading">Agree</a-button>
    </template>
    <div class="px-4 py-2 bg-white">
      <a-tabs v-model:activeKey="tabactiveKey" type="card" class="privacy-tabs">
        <a-tab-pane key="1" tab="Privacy Statement">
          <div class="tab-content">
            <h2><strong>Introduction</strong></h2>
            <p>
This Privacy Statement explains how we will collect, handle, store and protect information about you and certain employee(s), third party vendor(s), customer(s), lender(s), creditor(s), debtor(s), depositor(s) of your organisation collected from (1) the Tech TDS Application Software (referred to as the <b>“Application”</b>) and/or (2) while providing services. This Privacy Statement also sets out your rights in relation to personal information and tells you who you can contact if you have questions. By using the Application and/or by receiving services, you agree to the use of such information in accordance with this Privacy Statement.
            </p>
            <!-- <p>This Privacy Statement is divided into the sections listed below. </p>
            <p>
              <ol style="list-style-type: disc;">
                <li>To whom this Privacy Statement applies and what it covers</li>
                <li>Types of personal information we collect</li>
                <li>How we collect personal information</li>
                <li>Log information, cookies and web beacons</li>
                <li>Personal information provided by or about third parties</li>
                <li>How we use personal information</li>
                <li>On what basis we process personal information</li>
                <li>To whom we disclose personal information</li>
                <li>Selling of personal information</li>
                <li>Privacy practices of third parties</li>
                <li>Do not track</li>
                <li>How we keep personal information secure</li>
                <li>How long we will keep personal information</li>
                <li>Your rights in relation to personal information</li>
                <li>Changes to this Privacy Statement</li>
                <li>Contact us</li>
              </ol>
            </p> -->
            <a-collapse v-model:activeKey="coolpseactiveKey" accordion :expand-icon-position="'right'" class="privacy-collapse">
              <a-collapse-panel class="bg-light-2" key="1" header="To whom this Privacy Statement applies and what it covers">
                <p><b>When used in this Privacy Statement “Deloitte”, “we”, “us” and “our” refers to any entity within the Deloitte Network that invited you to use the Application and/or is involved in the provision of services.</b></p>
                <p>As used in this Privacy Statement, the <b>“Deloitte Network”</b> refers to one or more of Deloitte Touche Tohmatsu India LLP (“DTTILLP”), its network of member firms and the affiliates of such member firms or its authorized third parties. DTTILLP and each of its member firms and the affiliates of such member firms are legally separate and independent entities. Please see deloitte.com/about for a detailed description of the legal structure of DTTILLP and its member firms.</p>
                <p>This Privacy Statement is being provided to you in connection with your use of the Application and/or services. It sets out how the Application will process the personal information for review and preparation of TDS and TCS compliances and prepare reconciliations. Deloitte is providing these services either under a direct contract with you or via a contract with another person (such as a company or a partnership or a trustee) who has asked us to provide the services.</p>
                <p>Personal information will be protected and handled with consideration for its confidentiality.</p>
                <p>In this Privacy Statement, we refer to handling, collecting, protecting and storing personal information as "processing".</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="2" header="Types of personal information we collect">
                <p>Deloitte may collect personal information relating to you such as:</p>
                <p>At the time of activation of subscription</p>
                <ol style="list-style-type: disc;">
                  <li>Email ID of client personnel/ admin, professional job title.</li>
                </ol>
                <p>At the time of usage of the tool by users</p>
                <ol style="list-style-type: disc;">
                  <li>Name, PAN, TAN, Aadhaar, email id, contact number, address and financial transaction pertaining to the individual(s) with whom a transaction is undertaken and a payment for the same is made/ to be made and/ or payment for the same is collected/ to be collected</li>
                  <li>Name, email id and contact number pertaining to the individual(s) representing an organization with whom a transaction is undertaken and a payment for the same is made/ to be made and/ or payment for the same is collected/ to be collected</li>
                  <li>Name, PAN, TAN, Aadhaar, email id and address pertaining to the individual who is responsible to deduct/ collect TDS/ TCS</li>
                </ol>
                <p>Any other personal information of employee(s), third party vendor(s), customer(s), lender(s), creditor(s), debtor(s), depositor(s) of your organisation which is required for review and preparation of TDS and TCS compliances and prepare reconciliations.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="3" header="How we collect personal information">
                <p>Deloitte may collect personal information about you in different ways:</p>
                <ol style="list-style-type: disc;">
                  <li>you may upload the data onto the Application</li>
                  <li>you may provide it directly to us</li>
                  <li>we may obtain it because of the services that Deloitte provides or has previously provided</li>
                  <li>we may observe or infer it from the information you provide to us and/or the way you interact with us</li>
                </ol>
                <p>This personal information can be received in any manner, including in-person discussions, telephone conversations, and electronic or other written communications.</p>
                <p>Without access to all the personal information that we need, Deloitte may be unable to provide or complete the services.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="4" header="Log information, cookies and web beacons">
                <p>When you access the Application, we may collect standard Internet log information including your IP address, browser type and language, access times and referring website addresses. To ensure that the Application is well managed and to facilitate improved navigation, we or our service provider(s) may also use cookies (small text files stored in a user's browser) or web beacons (electronic images that allow us to count users who have accessed particular content and to access certain cookies) to collect aggregate data.</p>
                <p>When we use cookies, we may use strictly necessary cookies which are necessary to enable you to move around the Application and use its features such as accessing secure areas that may contain content for registered users.</p>
                <p>We may also use functional cookies to record information about the choices you have made and to allow us to tailor this Application to our users; for example, to remember your language or region. This information is usually anonymized and is not used for any purpose unrelated to this Application.</p>
                <p>We or our service providers may also use analytic services to help us understand how effective our content is, what interests our users have, and to improve how this Application works. In addition, we may also use web beacons, tracking pixels and similar services to understand more about the visitors to this Application, and to count visitor numbers and performance cookies to track how many individual users access this Application and how often. When collected, this information is used for statistical purposes and to improve how this Application works and it is not our intention to use such information to personally identify any user. However, if at any time you register and sign into this Application, we may combine such data with information from our web analytic services and cookies to analyze how you use this Application in more detail.</p>
                <p>You may be able to change your browser settings to manage and control cookies. You can find detailed information on how to do so at http://www.allaboutcookies.org/.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="5" header="Personal information provided about third parties">
                <p>If any personal information which you provide to us relates to any third party (for example, employee(s), third party vendor(s), customer(s), lender(s), creditor(s), debtor(s), depositor(s) of your organisation) then by providing us with their personal information you will need to ensure that you have obtained any necessary permissions from those persons to the use of their personal information in the way set out in this Privacy Statement, or you are otherwise permitted to give us this personal information. You should share a copy of this Privacy Statement with those other individuals before disclosing any personal information about them to us.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="6" header="How we use personal information">
                <p>Deloitte processes personal information to provide services to you or to the entity that has entered into subscription agreement or service arrangement with us as regards the Application.</p>
                <p>Deloitte may also use personal information for the purposes of, or in connection with:</p>
                <ol style="list-style-type: disc;">
                  <li>compliance with applicable legal, regulatory or professional requirements</li>
                  <li>protecting our rights and/or property</li>
                  <li>developing or improving services and products.</li>
                </ol>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="7" header="On what basis we process personal information">
                <p>This Privacy Statement sets out the grounds upon which we rely in order to process personal information.</p>
                <p>Deloitte may use personal information for the purposes outlined above because:</p>
                <p>(a) where relevant, we have a contract with you to provide services and processing personal information is necessary for the performance of such contract.</p>
                <p>or</p>
                <p>(b) we are subject to legal, regulatory or professional obligations.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="8" header="To whom we disclose personal information">
                <p>In connection with one or more of the purposes outlined in this Privacy Statement, we may disclose personal information to:</p>
                <ol style="list-style-type: disc;">
                  <li>other firms within the Deloitte Network</li>
                  <li>competent authorities, including courts and authorities regulating us or another firm within the Deloitte Network, in each case to comply with legal, regulatory or professional obligations, process or requests</li>
                  <li>vendors and administrative support, infrastructure and other service providers handling your information on our behalf; in each case, such vendors and service providers will be contractually bound by confidentiality and privacy obligations consistent with the obligations in this Privacy Statement</li>
                  <li>third parties to whom we disclose information in the course of providing services to you or to the entity that has engaged us to provide the services.</li>
                </ol>
                <p>Any personal information that we have referenced above under “How we collect personal information” may be disclosed to the third parties identified in this section for the purposes set forth herein.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="9" header="Selling of personal information">
                <p>We do not sell your personal information.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="10" header="Privacy practices of third parties">
                <p>Other websites or applications/platforms that may be accessible through links from the Application have their own privacy notices and personal information collection, use and disclosure practices. We may also receive information directly from third parties. We encourage you to familiarize yourself with the privacy notices provided by these other parties prior to providing them with information.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="11" header="Do not track">
                <p>"Do Not Track" is a preference you can set in your web browser to let websites and applications you visit know that you do not want them collecting information about you. The Platform does not currently respond to a "Do Not Track" or similar signal.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="12" header="How we keep personal information secure">
                <p>We have in place reasonable commercial standards of technology and operational security to protect personal information from loss, misuse and unauthorized access, disclosure, alteration or destruction.</p>
                <p>The admin right at an Application level is with Deloitte personnel. In case of subscription model, the admin right at a client level is with licensee admin (client admin basically). The licensee admin will create client account, user master, role creations, etc. Deloitte admin cannot see any data of any client. Role based access control (RBAC) has been implemented on the Application. Users will view/ edit access to data based on role assigned, organizational structure that he/ she is mapped.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="13" header="How long we will keep personal information">
                <p>We retain personal information as long as is necessary to fulfill the purposes identified in this Privacy Statement or (i) as otherwise necessary to comply with applicable laws or professional standards, or (ii) as long as the period in which litigation or investigations might arise in respect of our services.</p>
                <p>Without prejudice to the above,</p>
                <ol style="list-style-type: disc;">
                  <li>Source data which are uploaded by the users in the Application and/ or data processed and generated from the Application will be kept 2 years from date of expiry or termination;</li>
                  <li>On termination of client engagement, data will be available for the user to view/ download from the application for a period of 2 months from the date of expiry or termination.</li>
                </ol>
                <p>The Information shall not be retained beyond the period mentioned above from the date of collection.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="14" header="Your rights in relation to personal information">
                <p>You have various rights in relation to personal information. In particular, you have a right to:</p>
                <ol style="list-style-type: disc;">
                  <li>obtain confirmation that we are processing your personal information and request a copy of the personal information we hold about you </li>
                  <li>ask that we update the personal information we hold about you, or correct such information that you think is inaccurate or incomplete</li>
                </ol>
                <p>Depending on the jurisdiction in which you are located, you may also have the right to:</p>
                <ol style="list-style-type: disc;">
                  <li>ask that we delete personal information that we hold about you, or restrict the way in which we use your personal information</li>
                  <li>request that we provide the following information regarding the personal information we hold  about you:
                    <ol style="list-style-type: circle;">
                      <li>The categories and/or specific pieces of personal information we collected</li>
                      <li>The categories of sources from which personal information is collected</li>
                      <li>The business or commercial purpose for collecting personal information</li>
                      <li>The categories of third parties with whom we shared personal information</li>
                    </ol>
                  </li>
                </ol>
                <p>However, we may still retain a copy of the relevant information for as long as necessary to comply with applicable laws or professional standards, or as long as the period in which litigation or investigations might arise in respect of our services.</p>
                <p>To exercise any of your rights under applicable law described above regarding our use of personal information, please contact us at _____@deloitte.com.</p>
                <p>Applicable laws may also give you the right to lodge a complaint with a local supervisory authority related to this Privacy Statement.</p>
                <p>We will not discriminate against you for exercising any of your rights with respect to personal information.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="15" header="Changes to this Privacy Statement ">
                <p>In addition to describing our current privacy practices, this Privacy Statement also describes the categories of personal information we collected or disclosed. We may modify or amend this Privacy Statement from time to time at our discretion. When we make changes to this Privacy Statement, we will amend the revision date at the top of this page and the modified or amended Privacy Statement shall apply to personal information as of that revision date. We encourage you to review this Privacy Statement periodically to be informed about how we are protecting your personal information.</p>
              </a-collapse-panel>
              <a-collapse-panel class="bg-light-2" key="16" header="Contact us">
                <p>If you have any questions or concerns regarding this Privacy Statement or you are unsatisfied with the way in which personal information has been processed or the manner in which a privacy query or request that you have raised has been handled, please contact by emailing <a href="mailto:___@deloitte.com">___@deloitte.com.</a></p>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Terms of Use">
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-modal>
</template>

<script>
import {
  MenuOutlined,
  DownOutlined
} from '@ant-design/icons-vue'
import { defineComponent, reactive, toRefs, ref } from 'vue'
import headers from './constants/header'
import sidenav from './constants/sidenav'
import userRoles from './constants/userRoles'
import NProgress from 'nprogress'
import { notification } from 'ant-design-vue';
// import ModalIdle from '../components/ModalIdle'

export default defineComponent({
  setup() {
    const coolpseactiveKey = ref([]);
    const policyModal = ref(false);

    const showPolicyModal = (show) => {
      policyModal.value = show;
    };
    const handlepPlicyModalOk = (e) => {
      console.log(e);
      policyModal.value = false;
    };

    const visible = ref(false)
    const gotPermissions = ref(false)
    const loggedinUserInfo = ref({})
    const userModules = ref([])
    const loggedinUserPermissions = ref([])
    const showModal = () => {
      visible.value = true
    }
    const handleOk = (e) => {
      console.log(e)
      visible.value = false
    }
    const handleCancel = (e) => {
      console.log(e)
      visible.value = false
    }
    const state = reactive({
      rootSubmenuKeys: sidenav.map(item => item.subName),
      openKeys: [window.location.pathname?.split('/')[2]],
      selectedKeys: [(window.location.pathname?.split('/')[3]) ? window.location.pathname?.split('/')[3] : window.location.pathname?.split('/')[1]],
      showSidenav: 'no',
      currentUserRoles: [],
      currentUserRole: ''
    })
    const onOpenChange = (openKeys) => {
      const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1)
      if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        state.openKeys = openKeys
      } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : []
        state.selectedKeys = []
      }
    }
    const noPermissionsNotification = (type, messgage, description) => {
      notification[type]({
        message: messgage,
        description: description
      });
    };
    return {
      ...toRefs(state),
      onOpenChange,
      collapsed: ref(false),
      visible,
      showModal,
      handleOk,
      handleCancel,
      loggedinUserInfo,
      loggedinUserPermissions,
      gotPermissions,
      userModules,
      noPermissionsNotification,
      showPolicyModal,
      policyModal,
      handlepPlicyModalOk,
      tabactiveKey: ref('1'),
      coolpseactiveKey,
      agreeClickLoading: ref(false),
      showHeader: ref(true),
      env: ref(process.env.VUE_APP_Env)
    }
  },
  components: {
    MenuOutlined,
    DownOutlined,
    // ModalIdle
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      headers,
      sidenav,
      current: '/dashboard/',
      selectedHeader: null,
      selectedHeader1: ref(['TDS']),
      sidenavMenu: [],
      currentUserRoles: [],
      currentUserRole: '',
      roleChange: false
    }
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle
    },
    user() {
      return { ...this.$oidc.userProfile, accessToken: this.$oidc.accessToken }
    }
  },
  methods: {
    goto(item) {
      console.log(item)
      history.pushState(null, item.activeRule, item.activeRule)
      this.selectedKeys = [item.subName]
      this.openKeys = []
    },
    gotoSubMenu(item) {
      history.pushState(null, item.activeRule, item.activeRule)
      this.selectedKeys = [item.subName]
    },
    getThumnbailUsername(username) {
      if(!username)
        return null
      const firstLetters = username
        .split(' ')
        .map(word => word.charAt(0))
        .join('');

      return firstLetters.toUpperCase();
    },
    handleChange(value) {
      this.showHeader = false
      const oldRoleId = sessionStorage.getItem('roleId');
      sessionStorage.setItem('roleId', value)
      this.currentUserRole = sessionStorage.getItem('roleId')
      NProgress.start()
      this.roleChange = true;
      // location.href = '/'
      fetch(`${process.env.VUE_APP_apiGatewayRestURL}api/administration/rolepermissions/byroleid?id=${sessionStorage.getItem('roleId')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        }
      })
        .then(response => response.json())
        .then(userPermission => {
          const permissions = userPermission.permissions
          if((permissions && permissions.length == 0) || permissions[0] == ''){
            sessionStorage.setItem('roleId', oldRoleId)
            this.currentUserRole = oldRoleId;
            this.noPermissionsNotification('error', 'Access Restricted', 'No any permissions are availble to accees, Please contact with admin')
            NProgress.done()
            return
          }
          const modules = userPermission.modules
          sessionStorage.setItem('UserPermissions', JSON.stringify(permissions))
          sessionStorage.setItem('UserModules', JSON.stringify(modules))
          NProgress.done()
          this.handlePostAuthenticate(JSON.parse(sessionStorage.getItem('UserInfo')), permissions, modules, JSON.parse(sessionStorage.getItem('userRoles')))
        })
    },    
    handleAgree() {
      this.agreeClickLoading = true
      fetch(`${process.env.VUE_APP_apiGatewayRestURL}api/administration/privacy?action=UPDATE`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.user.accessToken
        }
      })
        .then(response => response.json())
        .then(response => {
          console.log(response)
          this.policyModal = false
          this.agreeClickLoading = false
        })
        .catch(error => {
          console.log(error)
          this.agreeClickLoading = false
        })
    },
    gotoHeader(item) {
      if (this.currentUserRole === userRoles.Checker) {
        this.selectedHeader = item.subName
        if (item.name === 'TDS') {
          sessionStorage.setItem('selectedModule', 'TDS')
          sessionStorage.setItem('urlModuleName', 'tds')
          history.pushState(null, '/tds/liability/approval/liability', '/tds/liability/approval/liability')
        } else {
          sessionStorage.setItem('selectedModule', 'TCS')
          sessionStorage.setItem('urlModuleName', 'tcs')
          history.pushState(null, '/tcs/liability/approval/liability', '/tcs/liability/approval/liability')
        }
      }
      else {
        this.selectedHeader = item.subName
        // history.pushState(null, item.activeRule, item.activeRule)
        if (item.subName !== 'dashboard') {
          this.openKeys = [item.activeRule.split('/')[2]]
          this.selectedKeys = [item.activeRule.split('/')[3]]
        }
        const moduleName = this.selectedHeader
        sessionStorage.setItem('urlModuleName', moduleName)
        sessionStorage.setItem('selectedModule', this.selectedHeader?.toUpperCase())
        this.sidenavMenu = this.sidenav.find((item) => { return item.moduleName === moduleName })?.menu
        for (let i = 0; i < this.sidenavMenu.length; i++) {
          if (this.loggedinUserPermissions?.indexOf(this.sidenavMenu[i].permission) > -1) {
            if (this.sidenavMenu[i].children) {
              for (let j = 0; j < this.sidenavMenu[i].children.length; j++) {
                if (this.loggedinUserPermissions?.indexOf(this.sidenavMenu[i].children[j].permission) > -1) {
                  history.pushState(null, this.sidenavMenu[i].children[j].activeRule, this.sidenavMenu[i].children[j].activeRule)
                  break
                }
              }
              break
            } else {
              history.pushState(null, this.sidenavMenu[i].activeRule, this.sidenavMenu[i].activeRule)
              break
            }
          }
        }
        this.bindCurrent()
      }
    },
    logout() {
      sessionStorage.clear();
      this.$oidc.signOut();
    },
    onWindowLoad() {
      if(sessionStorage.getItem('LoggedinUserId') && this.env === 'UAT') {
        this.loadCookiesScript()
      }
    },
    bindCurrent() {
      const path = window.location.pathname
      const pathSegments = path.split('/')
      if (String(sessionStorage.getItem('roleId')) === userRoles.Checker) {
        this.showSidenav = 'no'
      } else {
        this.showSidenav = 'yes'
        if (pathSegments[2] == 'masters' || pathSegments[2] == 'compliances') {
          this.openKeys = [pathSegments[2]]
          this.selectedKeys = [pathSegments[3]]
        } else if (pathSegments[1] == 'onboarding') {
          this.openKeys = []
          this.selectedKeys = [pathSegments[1]]
        } else if (pathSegments[1] == 'administration') {
          this.openKeys = []
          this.selectedKeys = [pathSegments[2] == 'settings' ? pathSegments[2] : pathSegments[1]]
        } else if (pathSegments[1] == 'masters') {
          this.openKeys = [pathSegments[1]]
          this.selectedKeys = [pathSegments[3]]
        }  else {
          this.openKeys = []
          this.selectedKeys = [pathSegments[2]]
        }
      }
      if (sessionStorage.getItem('roleId') === userRoles.SuperAdmin) {
        this.sidenavMenu = this.sidenav.find((item) => { return item.moduleId === 6 })?.menu
      } else if (sessionStorage.getItem('roleId') === userRoles.Checker) {
        this.sidenavMenu = this.sidenav.find((item) => { return item.moduleId === 5 })?.menu
        this.userModules = this.userModules.slice(0, 2)
      } else {
        this.sidenavMenu = this.sidenav.find((item) => { return item.moduleName === this.selectedHeader })?.menu
      }
    },
    listenRouterChange() {
      const _wr = function (type) {
        const orig = history[type]
        return function () {
          const rv = orig.apply(this, arguments)
          const e = new Event(type)
          e.arguments = arguments
          window.dispatchEvent(e)
          return rv
        }
      }
      history.pushState = _wr('pushState')

      // window.addEventListener('pushState', this.bindCurrent)
      window.addEventListener('popstate', this.bindCurrent)
      window.addEventListener('load', this.onWindowLoad)
    },
    handlePostAuthenticate(redisInfo, permissions, modules, currenUserRoles) {
      this.showHeader = true
      sessionStorage.setItem('apiGatewayRestURL', process.env.VUE_APP_apiGatewayRestURL);
      this.loggedinUserInfo = redisInfo
      this.loggedinUserPermissions = permissions
      this.gotPermissions = true
      this.currentUserRole = sessionStorage.getItem('roleId')
      if (this.currentUserRole === userRoles.Checker) {
        this.showSidenav = 'no'
      } else {
        this.showSidenav = 'yes'
      }
      this.currentUserRoles = currenUserRoles
      this.userModules = modules
      if ((this.userModules && this.userModules.length > 0 && window.location.pathname === '/') || this.roleChange) {
        this.roleChange = false;
        if (this.currentUserRole === userRoles.Checker) {
          // this.userModules = []
          this.userModules = this.userModules.slice(0, 2)
          this.sidenavMenu = this.sidenav.find((item) => { return item.moduleId === 5 })?.menu
          
          this.selectedHeader = this.userModules[0].toLowerCase();
          if (this.selectedHeader === 'tds') {
            sessionStorage.setItem('selectedModule', 'TDS')
            sessionStorage.setItem('urlModuleName', 'tds')
            history.pushState(null, '/tds/liability/approval/liability', '/tds/liability/approval/liability')
          } else {
            sessionStorage.setItem('selectedModule', 'TCS')
            sessionStorage.setItem('urlModuleName', 'tcs')
            history.pushState(null, '/tcs/liability/approval/liability', '/tcs/liability/approval/liability')
          }
          // for (let i = 0; i < this.sidenavMenu.length; i++) {
          //   if (this.loggedinUserPermissions?.indexOf(this.sidenavMenu[i].permission) > -1) {
          //     console.log(this.loggedinUserPermissions?.indexOf(this.sidenavMenu[i].permission) > -1)
          //     if (this.sidenavMenu[i].children) {
          //       for (let j = 0; j < this.sidenavMenu[i].children.length; j++) {
          //         if (this.loggedinUserPermissions?.indexOf(this.sidenavMenu[i].children[j].permission) > -1) {
          //           history.pushState(null, this.sidenavMenu[i].children[j].activeRule, this.sidenavMenu[i].children[j].activeRule)
          //           break
          //         }
          //       }
          //       break
          //     } else {
          //       history.pushState(null, this.sidenavMenu[i].activeRule, this.sidenavMenu[i].activeRule)
          //       break
          //     }
          //   }
          // }
        } else if (sessionStorage.getItem('roleId') === '1') {
          // this.userModules = []
          this.sidenavMenu = this.sidenav.find((item) => { return item.moduleId === 6 })?.menu
          history.pushState(null, this.sidenavMenu[0].activeRule, this.sidenavMenu[0].activeRule)
        } else {
          const moduleName = ((this.userModules[0] === 'Form 26 AS') ? 'form26as' : (this.userModules[0] === 'Clause 34 Reconciliation') ? 'clause34' : this.userModules[0]).toLowerCase()
          sessionStorage.setItem('urlModuleName', moduleName)
          this.selectedHeader = moduleName
          this.sidenavMenu = this.sidenav.find((item) => { return item.moduleName === moduleName })?.menu
          for (let i = 0; i < this.sidenavMenu.length; i++) {
            if (this.loggedinUserPermissions?.indexOf(this.sidenavMenu[i].permission) > -1) {
              if (this.sidenavMenu[i].children) {
                for (let j = 0; j < this.sidenavMenu[i].children.length; j++) {
                  if (this.loggedinUserPermissions?.indexOf(this.sidenavMenu[i].children[j].permission) > -1) {
                    history.pushState(null, this.sidenavMenu[i].children[j].activeRule, this.sidenavMenu[i].children[j].activeRule)
                    break
                  }
                }
                break
              } else {
                history.pushState(null, this.sidenavMenu[i].activeRule, this.sidenavMenu[i].activeRule)
                break
              }
            }
          }
        }
        sessionStorage.setItem('selectedModule', this.selectedHeader?.toUpperCase())
        this.bindCurrent();
      } else {
        this.selectedHeader = sessionStorage.getItem('urlModuleName')
        sessionStorage.setItem('selectedModule', this.selectedHeader?.toUpperCase())
        this.bindCurrent();
      }
      if (sessionStorage.getItem('roleId') !== userRoles.SuperAdmin) {
        fetch(`${process.env.VUE_APP_apiGatewayRestURL}api/administration/entity/byuserID?FK_UserID=${redisInfo.userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.user.accessToken
          }
        })
        .then(response => response.json())
        .then(userInfo => {
          var userObject = []
          var userTans = []
          userInfo.forEach((element, index) => {
            userObject.push({ Name: element.entityName + ' - ' + element.entityPAN, value: element.entityPAN, tdsMappedTemplateTypes: element.tdsMappedTemplateTypes, tcsMappedTemplateTypes: element.tcsMappedTemplateTypes, form26MappedTemplateTypes: element.form26MappedTemplateTypes })
            if (userInfo[index].tanDetails.length !== 0) {
              userInfo[index].tanDetails.forEach(tanInfo => {
                userTans.push({ entityId: element.pK_EntitiyID, Name: tanInfo.tanName + '-' + tanInfo.tan, value: tanInfo.tan, pan: userInfo[index].entityPAN })
              })
            }
          })
          sessionStorage.setItem('userPans', JSON.stringify(userObject))
          sessionStorage.setItem('userTans', JSON.stringify(userTans))
        })
        fetch(`${process.env.VUE_APP_apiGatewayRestURL}api/administration/privacy?action=GET`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.user.accessToken
          }
        })
          .then(response => response.json())
          .then(response => {
            console.log(response)
            if(response){
              this.policyModal = true;
            }
          })
      }
    },
    loadCookiesScript() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js')
      recaptchaScript.setAttribute('type', 'text/javascript')
      recaptchaScript.setAttribute('charset', 'UTF-8')
      recaptchaScript.setAttribute('data-domain-script', 'e463c141-d6e5-43d0-a25e-a672f2a4139d')
      document.head.appendChild(recaptchaScript)
    }
  },
  created() {
    this.bindCurrent()
  },
  mounted() {
    this.listenRouterChange()
    setTimeout(() => {
      if(!this.$oidc.isAuthenticated){
        window.location.href = `${process.env.VUE_APP_authority}/Account/Login`
      }
    }, 15000);
    sessionStorage.setItem('identityServiceUrl', `${process.env.VUE_APP_authority}/Account/Login`)
  },
  beforeMount() {
    if (window.location.pathname == '/login') {
      window.location.href = `${process.env.VUE_APP_authority}/Account/Login`
      return;
    }
    setTimeout(() => {
      if (this.$oidc.isAuthenticated) {
        if (!sessionStorage.getItem('LoggedinUserId')) {
          fetch(`${process.env.VUE_APP_apiGatewayRestURL}api/getusercontext`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.user.accessToken
            }
          }).then(response => response.json())
            .then(redisInfo => {
              const originalUserRoles = JSON.parse(redisInfo.roles)
              const userRoles = []
              // manage roles in order
              let rolesOrder = ['2', '3', '4', '1'];// defined role order
              for (let i = 0; i < rolesOrder.length; i++) {
                  let roleIndex = originalUserRoles.findIndex(role => role.RoleId == rolesOrder[i])
                  if(roleIndex != -1){
                    userRoles.push(originalUserRoles[roleIndex])
                  }
              }
              sessionStorage.setItem('Username', redisInfo.userName)
              sessionStorage.setItem('LoggedinUserId', redisInfo.userId)
              sessionStorage.setItem('userRoles', JSON.stringify(userRoles))
              sessionStorage.setItem('token', this.user.accessToken)
              sessionStorage.setItem('tenantId', redisInfo.tenantId)
              sessionStorage.setItem('roleId', userRoles[0].RoleId)
              sessionStorage.setItem('UserInfo', JSON.stringify(redisInfo))
              if(this.env === 'UAT') {
                this.loadCookiesScript()
              }
              if (sessionStorage.getItem('roleId') === '1') {
                  const permissions = ['ADMIN_CLIENT_ONBOARDING', 'ADMIN_MASTERS', 'ADMIN_TDS_SECTION', 'ADMIN_TCS_SECTION', 'ADMIN_REASONING']
                  const modules = ['SuperAdmin']
                  sessionStorage.setItem('UserPermissions', JSON.stringify(permissions))
                  sessionStorage.setItem('UserModules', JSON.stringify(modules))
                  this.handlePostAuthenticate(redisInfo, permissions, modules, userRoles)
              } else {
                fetch(`${process.env.VUE_APP_apiGatewayRestURL}api/administration/rolepermissions/byroleid?id=${sessionStorage.getItem('roleId')}`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + sessionStorage.getItem('token')
                  }
                })
                .then(response => response.json())
                .then(userPermission => {
                  const permissions = userPermission.permissions
                  if((permissions && permissions.length == 0) || permissions[0] == ''){
                    this.noPermissionsNotification('error', 'Access Restricted', 'No any permissions are availble to accees, Please contact with admin')
                    this.logout()
                    return
                  }
                  const modules = userPermission.modules
                  sessionStorage.setItem('UserPermissions', JSON.stringify(permissions))
                  sessionStorage.setItem('UserModules', JSON.stringify(modules))
                  this.handlePostAuthenticate(redisInfo, permissions, modules, userRoles)
                })
              }
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          if (window.location.pathname === '/') {
            if (sessionStorage.getItem('roleId') === '1') {
                const permissions = ['ADMIN_CLIENT_ONBOARDING', 'ADMIN_MASTERS', 'ADMIN_TDS_SECTION', 'ADMIN_TCS_SECTION', 'ADMIN_REASONING']
                const modules = ['SuperAdmin']
                sessionStorage.setItem('UserPermissions', JSON.stringify(permissions))
                sessionStorage.setItem('UserModules', JSON.stringify(modules))
                this.handlePostAuthenticate(JSON.parse(sessionStorage.getItem('UserInfo')), permissions, modules, JSON.parse(sessionStorage.getItem('userRoles')))
            } else {
              fetch(`${process.env.VUE_APP_apiGatewayRestURL}api/administration/rolepermissions/byroleid?id=${sessionStorage.getItem('roleId')}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + sessionStorage.getItem('token')
                }
              })
              .then(response => response.json())
              .then(userPermission => {
                const permissions = userPermission.permissions
                if((permissions && permissions.length == 0) || permissions[0] == ''){
                  this.noPermissionsNotification('error', 'Access Restricted', 'No any permissions are availble to accees, Please contact with admin')
                  this.logout()
                  return
                }
                const modules = userPermission.modules
                sessionStorage.setItem('UserPermissions', JSON.stringify(permissions))
                sessionStorage.setItem('UserModules', JSON.stringify(modules))
                this.handlePostAuthenticate(JSON.parse(sessionStorage.getItem('UserInfo')), permissions, modules, JSON.parse(sessionStorage.getItem('userRoles')))
              })
            }
          } else {
            this.currentUserRole = sessionStorage.getItem('roleId')
            if (this.currentUserRole === userRoles.Checker) {
              this.showSidenav = 'no'
            }
            this.handlePostAuthenticate(JSON.parse(sessionStorage.getItem('UserInfo')), JSON.parse(sessionStorage.getItem('UserPermissions')), JSON.parse(sessionStorage.getItem('UserModules')), JSON.parse(sessionStorage.getItem('userRoles')))
          }
        }
      }
    }, 1000);
  }
})
</script>

<style lang="scss">
.tab-content {
  max-height: 500px;
  overflow: auto;
}

.privacy-collapse {
  background-color: #ffffff !important;
  border: none !important;

  .ant-collapse-content {
    background-color: rgba(209, 209, 209, 0.13) !important;
    border-top: none !important;
  }

  .ant-collapse-item {
    border-bottom: none !important;
    margin-bottom: 10px !important;

    .ant-collapse-header {
      font-weight: 700 !important;
    }
  }
}

.privacy-tabs {
  .ant-tabs-tab-btn {
    font-weight: 600 !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }

  .ant-tabs-tab-active {
    background: #007cb0 !important
  }
}

.ant-modal-title {
  font-size: 18px !important;
  font-weight: 700 !important;
}</style>
