const permissions = {
  VUE_APP_PERMISSION_TDS: 'TDS',
  VUE_APP_PERMISSION_TDS_MASTERS: 'TDS_MASTERS',
  VUE_APP_PERMISSION_TDS_VENDOR_MASTER: 'TDS_VENDOR_MASTER',
  VUE_APP_PERMISSION_TDS_FS_GROUPING_MASTER: 'TDS_FS_GROUPING_MASTER',
  VUE_APP_PERMISSION_TDS_LDC_MASTER: 'TDS_LDC_MASTER',
  VUE_APP_PERMISSION_TDS_VENDOR_MASTER_GL: 'TDS_VENDOR_MASTER_GL',
  VUE_APP_PERMISSION_TDS_VENDOR_MASTER_UPLOAD: 'TDS_VENDOR_MASTER_UPLOAD',
  VUE_APP_PERMISSION_TDS_VENDOR_MASTER_PAN_VALIDATION: 'TDS_VENDOR_MASTER_PAN_VALIDATION',
  VUE_APP_PERMISSION_TDS_VENDOR_MASTER_EXPORT: 'TDS_VENDOR_MASTER_EXPORT',
  VUE_APP_PERMISSION_TDS_VENDOR_MASTER_206AB_APPLICABILITY: 'TDS_VENDOR_MASTER_206AB_APPLICABILITY',
  VUE_APP_PERMISSION_TDS_FS_GROUPING_UPLOAD: 'TDS_FS_GROUPING_UPLOAD',
  VUE_APP_PERMISSION_TDS_FS_GROUPING_EXPORT: 'TDS_FS_GROUPING_EXPORT',
  VUE_APP_PERMISSION_TDS_LDC_MASTER_EXPORT: 'TDS_LDC_MASTER_EXPORT',
  VUE_APP_PERMISSION_TDS_LDC_MASTER_IMPORT_TRACES: 'TDS_LDC_MASTER_IMPORT_TRACES',
  VUE_APP_PERMISSION_TDS_VENDOR_MASTER_GL_UPLOAD: 'TDS_VENDOR_MASTER_GL_UPLOAD',
  VUE_APP_PERMISSION_TDS_VENDOR_MASTER_GL_EXPORT: 'TDS_VENDOR_MASTER_GL_EXPORT',
  VUE_APP_PERMISSION_TDS_LIABILITY: 'TDS_LIABILITY',
  VUE_APP_PERMISSION_TDS_START_NEW_PROCESS: 'TDS_START_NEW_PROCESS',
  VUE_APP_PERMISSION_TDS_REPROCESS: 'TDS_REPROCESS',
  VUE_APP_PERMISSION_TDS_CHALLAN: 'TDS_CHALLAN',
  VUE_APP_PERMISSION_TDS_CHALLAN_GENERATE: 'TDS_CHALLAN_GENERATE',
  VUE_APP_PERMISSION_TDS_CHALLAN_EXPORT: 'TDS_CHALLAN_EXPORT',
  VUE_APP_PERMISSION_TDS_COMPLIANCE: 'TDS_COMPLIANCE',
  VUE_APP_PERMISSION_TDS_RETURN: 'TDS_RETURN',
  VUE_APP_PERMISSION_TDS_RETURN_FILE: 'TDS_RETURN_FILE',
  VUE_APP_PERMISSION_TDS_CERTIFICATES: 'TDS_CERTIFICATES',
  VUE_APP_PERMISSION_TDS_REQUEST: 'TDS_REQUEST',
  VUE_APP_PERMISSION_TDS_APPROVALS: 'TDS_APPROVALS',
  VUE_APP_PERMISSION_TDS_REPORTS: 'TDS_REPORTS',
  VUE_APP_PERMISSION_TDS_DOCUMENT_REPOSITORY: 'TDS_DOCUMENT_REPOSITORY',
  VUE_APP_PERMISSION_TDS_SETTINGS: 'TDS_SETTINGS',
  VUE_APP_PERMISSION_TDS_ADMINISTRATION: 'TDS_ADMINISTRATION',
  VUE_APP_PERMISSION_TCS: 'TCS',
  VUE_APP_PERMISSION_TCS_MASTERS: 'TCS_MASTERS',
  VUE_APP_PERMISSION_TCS_FS_GROUPING_MASTER: 'TCS_FS_GROUPING_MASTER',
  VUE_APP_PERMISSION_TCS_FS_GROUPING_UPLOAD: 'TCS_FS_GROUPING_UPLOAD',
  VUE_APP_PERMISSION_TCS_FS_GROUPING_EXPORT: 'TCS_FS_GROUPING_EXPORT',
  VUE_APP_PERMISSION_TCS_CUSTOMER_MASTER: 'TCS_CUSTOMER_MASTER',
  VUE_APP_PERMISSION_TCS_LIABILITY: 'TCS_LIBIALITY',
  VUE_APP_PERMISSION_TCS_CUSTOMER_MASTER_EXPORT: 'TCS_CUSTOMER_MASTER_EXPORT',
  VUE_APP_PERMISSION_TCS_CUSTOMER_MASTER_UPLOAD: 'TCS_CUSTOMER_MASTER_UPLOAD',
  VUE_APP_PERMISSION_TCS_CUSTOMER_MASTER_PAN_VALIDATION: 'TCS_CUSTOMER_MASTER_PAN_VALIDATION',
  VUE_APP_PERMISSION_TCS_CUSTOMER_MASTER_206_APPLICABILITY: 'TCS_CUSTOMER_MASTER_206_APPLICABILITY',
  VUE_APP_PERMISSION_TCS_LCC_MASTER: 'TCS_LCC_MASTER',
  VUE_APP_PERMISSION_TCS_LCC_MASTER_IMPORT_TRACES: 'TCS_LCC_MASTER_IMPORT_TRACES',
  VUE_APP_PERMISSION_TCS_LCC_MASTER_EXPORT: 'TCS_LCC_MASTER_EXPORT',
  VUE_APP_PERMISSION_TCS_LIBIALITY: 'TCS_LIBIALITY',
  VUE_APP_PERMISSION_TCS_START_NEW_PROCESS: 'TCS_START_NEW_PROCESS',
  VUE_APP_PERMISSION_TCS_REPROCESS: 'TCS_REPROCESS',
  VUE_APP_PERMISSION_TCS_CHALLAN: 'TCS_CHALLAN',
  VUE_APP_PERMISSION_TCS_CHALLAN_VERIFY: 'TCS_CHALLAN_VERIFY',
  VUE_APP_PERMISSION_TCS_CHALLAN_GENERATE: 'TCS_CHALLAN_GENERATE',
  VUE_APP_PERMISSION_TCS_COMPLIANCE: 'TCS_COMPLIANCE',
  VUE_APP_PERMISSION_TCS_RETURN: 'TCS_RETURN',
  VUE_APP_PERMISSION_TCS_RETURN_FILE: 'TCS_RETURN_FILE',
  VUE_APP_PERMISSION_TCS_CERTIFICATES: 'TCS_CERTIFICATES',
  VUE_APP_PERMISSION_TCS_REQUEST: 'TCS_REQUEST',
  VUE_APP_PERMISSION_TCS_REPORTS: 'TCS_REPORTS',
  VUE_APP_PERMISSION_TCS_SETTINGS: 'TCS_SETTINGS',
  VUE_APP_PERMISSION_TCS_ADMINISTRATION: 'TCS_ADMINISTRATION',
  VUE_APP_PERMISSION_TCS_DOCUMENT_REPOSITORY: 'TCS_DOCUMENT_REPOSITORY',
  VUE_APP_PERMISSION_CLAUSE_34: 'CLAUSE_34',
  VUE_APP_PERMISSION_CLAUSE_34_MASTERS: 'CLAUSE_34_MASTERS',
  VUE_APP_PERMISSION_CLAUSE_34_VENDOR_MASTER: 'CLAUSE_34_VENDOR_MASTER',
  VUE_APP_PERMISSION_CLAUSE_34_VENDOR_MASTER_EXPORT: 'CLAUSE_34_VENDOR_MASTER_EXPORT',
  VUE_APP_PERMISSION_CLAUSE_34_VENDOR_MASTER_IMPORT: 'CLAUSE_34_VENDOR_MASTER_IMPORT',
  VUE_APP_PERMISSION_CLAUSE_34_VENDOR_MASTER_PAN_VALIDATION: 'CLAUSE_34_VENDOR_MASTER_PAN_VALIDATION',
  VUE_APP_PERMISSION_CLAUSE_34_VENDOR_MASTER_206_APPLICABILITY: 'CLAUSE_34_VENDOR_MASTER_206_APPLICABILITY',
  VUE_APP_PERMISSION_CLAUSE_34_LDC_MASTER: 'CLAUSE_34_LDC_MASTER',
  VUE_APP_PERMISSION_CLAUSE_34_LDC_TRACES_IMPORT: 'CLAUSE_34_LDC_TRACES_IMPORT',
  VUE_APP_PERMISSION_CLAUSE_34_LDC_MASTER_EXPORT: 'CLAUSE_34_LDC_MASTER_EXPORT',
  VUE_APP_PERMISSION_CLAUSE_34_FS_GROUPING_MASTER: 'CLAUSE_34_FS_GROUPING_MASTER',
  VUE_APP_PERMISSION_CLAUSE_34_FS_GROUPING_IMPORT: 'CLAUSE_34_FS_GROUPING_IMPORT',
  VUE_APP_PERMISSION_CLAUSE_34_FS_GROUPING_EXPORT: 'CLAUSE_34_FS_GROUPING_EXPORT',
  VUE_APP_PERMISSION_CLAUSE_34_REVIEW: 'CLAUSE_34_REVIEW',
  VUE_APP_PERMISSION_CLAUSE_34_REVIEW_INPUT: 'CLAUSE_34_REVIEW_INPUT',
  VUE_APP_PERMISSION_CLAUSE_34_REVIEW_OUTPUT: 'CLAUSE_34_REVIEW_OUTPUT',
  VUE_APP_PERMISSION_CLAUSE_34_REPORTS: 'CLAUSE_34_REPORTS',
  VUE_APP_PERMISSION_CLAUSE_34_DOCUMENT_REPOSITORY: 'CLAUSE_34_DOCUMENT_REPOSITORY',
  VUE_APP_PERMISSION_CLAUSE_34_ADMINISTRATION: 'CLAUSE_34_ADMINISTRATION',
  VUE_APP_PERMISSION_CLAUSE_34_SETTINGS: 'CLAUSE_34_SETTINGS',
  VUE_APP_PERMISSION_FORM_26_AS: 'FORM_26_AS',
  VUE_APP_PERMISSION_FORM_26_AS_MASTER: 'FORM_26_AS_MASTER',
  VUE_APP_PERMISSION_FORM_26_AS_CUSTOMER_MASTER: 'FORM_26_AS_CUSTOMER_MASTER',
  VUE_APP_PERMISSION_FORM_26_AS_CUSTOMER_MASTER_UPLOAD: 'FORM_26_AS_CUSTOMER_MASTER_UPLOAD',
  VUE_APP_PERMISSION_FORM_26_AS_GL_MASTER: 'FORM_26_AS_GL_MASTER',
  VUE_APP_PERMISSION_FORM_26_AS_GL_Master_UPLOAD: 'FORM_26_AS_GL_Master_UPLOAD',
  VUE_APP_PERMISSION_FORM_26_AS_RECONCILITION: 'FORM_26_AS_RECONCILITION',
  VUE_APP_PERMISSION_FORM_26_AS_REPORTS: 'FORM_26_AS_REPORTS',
  VUE_APP_PERMISSION_FORM_26_AS_DOCUMENT_REPOSITORY: 'FORM_26_AS_DOCUMENT_REPOSITORY',
  VUE_APP_PERMISSION_FORM_26_AS_SETTINGS: 'FORM_26_AS_SETTINGS',
  VUE_APP_PERMISSION_FORM_26_AS_ADMINISTRATION: 'FORM_26_AS_ADMINISTRATION',
  VUE_APP_PERMISSION_LIABILITY_APPROVAL: 'LIABILITY_APPROVAL',
  VUE_APP_PERMISSION_CHALLAN_APPROVAL: 'CHALLAN_APPROVAL',
  VUE_APP_PERMISSION_ADMIN_CLIENT_ONBOARDING: 'ADMIN_CLIENT_ONBOARDING',
  VUE_APP_PERMISSION_ADMIN_MASTERS: 'ADMIN_MASTERS',
  VUE_APP_PERMISSION_ADMIN_TDS_SECTION: 'ADMIN_TDS_SECTION',
  VUE_APP_PERMISSION_ADMIN_TCS_SECTION: 'ADMIN_TCS_SECTION',
  VUE_APP_PERMISSION_ADMIN_REASONING: 'ADMIN_REASONING'
}

export default permissions
