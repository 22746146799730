import permissions from './permissions'

interface headerItem {
  name: string,
  masterName: string,
  subName: string,
  activeRule: string,
  moduleId?: number,
  permission?: string
}

const headers: headerItem[] = [
  // {
  //   name: 'Dashboard',
  //   masterName: 'Dashboard',
  //   subName: 'dashboard',
  //   activeRule: '/dashboard/'
  // },
  {
    name: 'TDS',
    masterName: 'TDS',
    subName: 'tds',
    activeRule: '/tds/masters/fsgroup',
    moduleId: 1,
    permission: permissions.VUE_APP_PERMISSION_TDS
  },
  {
    name: 'TCS',
    masterName: 'TCS',
    subName: 'tcs',
    activeRule: '/tcs/masters/fsgroup',
    moduleId: 2,
    permission: permissions.VUE_APP_PERMISSION_TCS
  },
  {
    name: 'Clause 34 Reconciliation',
    masterName: 'Clause 34 Reconciliation',
    subName: 'clause34',
    activeRule: '/tds/masters/clause34/fsgroup',
    moduleId: 4,
    permission: permissions.VUE_APP_PERMISSION_CLAUSE_34
  },
  {
    name: 'Form 26AS Reconciliation',
    masterName: 'Form 26 AS',
    subName: 'form26as',
    activeRule: '/form26as/masters/customer-master',
    moduleId: 3,
    permission: permissions.VUE_APP_PERMISSION_FORM_26_AS
  }
]

export default headers
