import permissions from './permissions'
interface permissionItem {
  moduleId: number,
  moduleName: string,
  menu: Array<{
    name: string,
    subName: string,
    activeRule: string,
    active: boolean,
    iconPath: string,
    permission: string,
    children?: Array<{
      name: string,
      subName: string,
      activeRule?: string,
      permission?: string,
      isLabel?: boolean,
    }>
  }>,
}
 
const sidenav: permissionItem[] = [
  {
    moduleId: 1,
    moduleName: 'tds',
    menu: [
      {
        name: 'Masters',
        subName: 'masters',
        activeRule: '/tds/masters',
        active: true,
        iconPath: 'images/database_duotone.png',
        permission: permissions.VUE_APP_PERMISSION_TDS_MASTERS,
        children: [
          {
            name: 'FS Grouping Master',
            subName: 'fsgroup',
            activeRule: '/tds/masters/fsgroup',
            permission: permissions.VUE_APP_PERMISSION_TDS_FS_GROUPING_MASTER
          },
          {
            name: 'Vendor Master',
            subName: 'vendor',
            activeRule: '/tds/masters/vendor',
            permission: permissions.VUE_APP_PERMISSION_TDS_VENDOR_MASTER
          },
          {
            name: 'Vendor GL Mapping',
            subName: 'vendor-master-gl',
            activeRule: '/tds/masters/vendor-master-gl',
            permission: permissions.VUE_APP_PERMISSION_TDS_VENDOR_MASTER_GL
          },
          {
            name: 'LDC Master',
            subName: 'ldcmaster',
            activeRule: '/tds/masters/ldcmaster',
            permission: permissions.VUE_APP_PERMISSION_TDS_LDC_MASTER
          },
          {
            name: 'Vendor-Section YTD',
            subName: 'vendor-threshold-master',
            activeRule: '/tds/masters/vendor-threshold-master',
            permission: permissions.VUE_APP_PERMISSION_TDS_VENDOR_MASTER_GL
          }
        ]
      },
      {
        name: 'TDS Liability',
        subName: 'liability',
        activeRule: '/tds/liability',
        active: true,
        iconPath: 'images/File_dock_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TDS_LIABILITY
      },
      {
        name: 'Challans',
        subName: 'challans',
        activeRule: '/tds/challans',
        active: true,
        iconPath: 'images/Paper_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TDS_CHALLAN
      },
      {
        name: 'Compliances',
        subName: 'compliances',
        activeRule: '/tds/compliances',
        active: true,
        iconPath: 'images/Chield_check_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TDS_COMPLIANCE,
        children: [
          {
            name: 'TDS Return',
            subName: 'return',
            activeRule: '/tds/compliances/return',
            permission: permissions.VUE_APP_PERMISSION_TDS_RETURN
          },
          {
            name: 'TDS Certificates',
            subName: 'certificates',
            activeRule: '/tds/compliances/certificates',
            permission: permissions.VUE_APP_PERMISSION_TDS_CERTIFICATES
          }
        ]
      },
      {
        name: 'Reports',
        subName: 'reports',
        activeRule: '/tds/reports',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TDS_REPORTS
      },
      {
        name: 'Document Repository',
        subName: 'document',
        activeRule: '/tds/reports/document-repository',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TDS_DOCUMENT_REPOSITORY
      },
      // {
      //   name: 'Returns',
      //   subName: 'returns',
      //   activeRule: '/tds/returns',
      //   active: true,
      //   iconPath: 'images/Folder_line_duotone_line.png',
      //   permission: permissions.VUE_APP_PERMISSION_TDS_RETURN
      // },
      {
        name: 'Settings',
        subName: 'settings',
        activeRule: '/administration/settings',
        active: true,
        iconPath: 'images/Setting_line_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TDS_SETTINGS
      },
      {
        name: 'Administration',
        subName: 'administration',
        activeRule: '/administration',
        active: true,
        iconPath: 'images/User_cicrle_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TDS_ADMINISTRATION
      }
    ]
  },
  {
    moduleId: 2,
    moduleName: 'tcs',
    menu: [
      {
        name: 'Masters',
        subName: 'masters',
        activeRule: '/tcs/masters',
        active: true,
        iconPath: 'images/database_duotone.png',
        permission: permissions.VUE_APP_PERMISSION_TCS_MASTERS,
        children: [
          {
            name: 'FS Grouping Master',
            subName: 'fsgroup',
            activeRule: '/tcs/masters/fsgroup',
            permission: permissions.VUE_APP_PERMISSION_TCS_FS_GROUPING_MASTER
          },
          {
            name: 'Customer Master',
            subName: 'customer',
            activeRule: '/tcs/masters/customer',
            permission: permissions.VUE_APP_PERMISSION_TCS_CUSTOMER_MASTER
          },
          {
            name: 'Customer GL Mapping',
            subName: 'customer-mapping-gl',
            activeRule: '/tcs/masters/customer-mapping-gl',
            permission: permissions.VUE_APP_PERMISSION_TCS_FS_GROUPING_MASTER
          },
          // {
          //   name: 'Vendor GL Mapping',
          //   subName: 'vendor-master-gl',
          //   activeRule: '/tcs/masters/fsgroup',
          //   permission: permissions.VUE_APP_PERMISSION_TCS_FS_GROUPING_MASTER
          // },
          {
            name: 'LCC Master',
            subName: 'lccmaster',
            activeRule: '/tcs/masters/lccmaster',
            permission: permissions.VUE_APP_PERMISSION_TCS_LCC_MASTER
          },
          {
            name: 'Customer-Section YTD',
            subName: 'customer-threshold',
            activeRule: '/tcs/masters/customer-threshold',
            permission: permissions.VUE_APP_PERMISSION_TCS_LCC_MASTER
          },

        ]
      },
      {
        name: 'TCS Liability',
        subName: 'liability',
        activeRule: '/tcs/liability',
        active: true,
        iconPath: 'images/File_dock_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TCS_LIBIALITY
      },
      {
        name: 'Challans',
        subName: 'challans',
        activeRule: '/tcs/challans',
        active: true,
        iconPath: 'images/Paper_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TCS_CHALLAN
      },
      {
        name: 'Compliances',
        subName: 'compliances',
        activeRule: '/tcs/compliances',
        active: true,
        iconPath: 'images/Chield_check_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TCS_COMPLIANCE,
        children: [
          {
            name: 'TCS Return',
            subName: 'return',
            activeRule: '/tcs/compliances/return',
            permission: permissions.VUE_APP_PERMISSION_TCS_RETURN
          },
          {
            name: 'TCS Certificates',
            subName: 'certificates',
            activeRule: '/tcs/compliances/certificates',
            permission: permissions.VUE_APP_PERMISSION_TCS_CERTIFICATES
          }
        ]
      },
      {
        name: 'Reports',
        subName: 'reports',
        activeRule: '/tcs/reports',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TCS_REPORTS
      },
      {
        name: 'Document Repository',
        subName: 'document',
        activeRule: '/tcs/reports/document-repository',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TCS_DOCUMENT_REPOSITORY
      },
      {
        name: 'Returns',
        subName: 'returns',
        activeRule: '/tcs/returns',
        active: true,
        iconPath: 'images/Folder_line_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TCS_RETURN
      },
      {
        name: 'Settings',
        subName: 'settings',
        activeRule: '/administration/settings',
        active: true,
        iconPath: 'images/Setting_line_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TCS_SETTINGS
      },
      {
        name: 'Administration',
        subName: 'administration',
        activeRule: '/administration',
        active: true,
        iconPath: 'images/User_cicrle_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_TCS_ADMINISTRATION
      }
    ]
  },
  {
    moduleId: 3,
    moduleName: 'form26as',
    menu: [
      {
        name: 'Masters',
        subName: 'masters',
        activeRule: '/form26as/masters',
        active: true,
        iconPath: 'images/database_duotone.png',
        permission: permissions.VUE_APP_PERMISSION_FORM_26_AS_MASTER,
        children: [
          {
            name: 'Customer Master',
            subName: 'customer-master',
            activeRule: '/form26as/masters/customer-master',
            permission: permissions.VUE_APP_PERMISSION_FORM_26_AS_CUSTOMER_MASTER
          },
          {
            name: 'G/L Master',
            subName: 'gl-master',
            activeRule: '/form26as/masters/gl-master',
            permission: permissions.VUE_APP_PERMISSION_FORM_26_AS_GL_MASTER
          }
        ]
      },
      {
        name: 'Reconciliation',
        subName: 'reconcilation',
        activeRule: '/form26as/reconcilation',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_FORM_26_AS_RECONCILITION
      },
      {
        name: 'Reports',
        subName: 'reports',
        activeRule: '/form26as/reports',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_FORM_26_AS_REPORTS
      },
      {
        name: 'Settings',
        subName: 'settings',
        activeRule: '/administration/settings',
        active: true,
        iconPath: 'images/Setting_line_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_FORM_26_AS_SETTINGS
      },
      {
        name: 'Administration',
        subName: 'administration',
        activeRule: '/administration',
        active: true,
        iconPath: 'images/User_cicrle_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_FORM_26_AS_ADMINISTRATION
      }
    ]
  },
  {
    moduleId: 4,
    moduleName: 'clause34',
    menu: [
      {
        name: 'Master Files',
        subName: 'masters',
        activeRule: '/tds/masters/clause34/fsgroup',
        active: true,
        iconPath: 'images/database_duotone.png',
        permission: permissions.VUE_APP_PERMISSION_CLAUSE_34_MASTERS,
        children: [
          {
            name: 'TDS',
            subName: 'tds',
            isLabel: true
          },
          {
            name: 'FS Grouping Master',
            subName: 'tdsfsgroup',
            activeRule: '/tds/masters/clause34/fsgroup',
            permission: permissions.VUE_APP_PERMISSION_TDS_FS_GROUPING_MASTER
          },
          {
            name: 'Vendor Master',
            subName: 'tdsvendor',
            activeRule: '/tds/masters/clause34/vendor',
            permission: permissions.VUE_APP_PERMISSION_TDS_VENDOR_MASTER
          },
          {
            name: 'LDC Master',
            subName: 'tdsldcmaster',
            activeRule: '/tds/masters/clause34/ldcmaster',
            permission: permissions.VUE_APP_PERMISSION_TDS_LDC_MASTER
          },
          {
            name: 'TCS',
            subName: 'tcs',
            isLabel: true
          },
          {
            name: 'FS Grouping Master',
            subName: 'tcsfsgroup',
            activeRule: '/tcs/masters/clause34/fsgroup',
            permission: permissions.VUE_APP_PERMISSION_TCS_FS_GROUPING_MASTER
          },
          {
            name: 'Customer Master',
            subName: 'tcscustomer',
            activeRule: '/tcs/masters/clause34/customer',
            permission: permissions.VUE_APP_PERMISSION_TCS_CUSTOMER_MASTER
          },
          {
            name: 'LCC Master',
            subName: 'tcsldcmaster',
            activeRule: '/tcs/masters/clause34/lccmaster',
            permission: permissions.VUE_APP_PERMISSION_TCS_LCC_MASTER
          }
        ]
      },
      {
        name: 'Review',
        subName: 'review',
        activeRule: '/clause34/review',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_CLAUSE_34_REVIEW
      },
      {
        name: 'Reports',
        subName: 'reports',
        activeRule: '/clause34/reports',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_CLAUSE_34_REPORTS
      },
      {
        name: 'Document Repository',
        subName: 'docrepo',
        activeRule: '/clause34/docrepo',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_CLAUSE_34_DOCUMENT_REPOSITORY
      },
      {
        name: 'Settings',
        subName: 'settings',
        activeRule: '/administration/settings',
        active: true,
        iconPath: 'images/Setting_line_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_CLAUSE_34_SETTINGS
      },
      {
        name: 'Administration',
        subName: 'administration',
        activeRule: '/administration',
        active: true,
        iconPath: 'images/User_cicrle_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_CLAUSE_34_ADMINISTRATION
      }
    ]
  },
  // checker role permissions 
  {
    moduleId: 5,
    moduleName: 'checker',
    menu: [
      {
        name: 'Liability Approval',
        subName: 'liabilityapproval',
        activeRule: '/tds/liability/approval/liability',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_LIABILITY_APPROVAL
      },
      {
        name: 'Challan Approval',
        subName: 'challanapproval',
        activeRule: '/tds/liability/approval/challan',
        active: true,
        iconPath: 'images/Order_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_CHALLAN_APPROVAL
      }
    ]
  },
  // superadmin permissions
  {
    moduleId: 6,
    moduleName: 'superadmin',
    menu: [
      {
        name: 'Client',
        subName: 'onboarding',
        activeRule: '/onboarding/client',
        active: true,
        iconPath: 'images/User_cicrle_duotone_line.png',
        permission: permissions.VUE_APP_PERMISSION_ADMIN_CLIENT_ONBOARDING
      },
      {
        name: 'Masters',
        subName: 'masters',
        activeRule: '/masters',
        active: true,
        iconPath: 'images/database_duotone.png',
        permission: permissions.VUE_APP_PERMISSION_ADMIN_MASTERS,
        children: [
          {
            name: 'TDS Section Master',
            subName: 'nopratemaster',
            activeRule: '/masters/tds/nopratemaster',
            permission: permissions.VUE_APP_PERMISSION_ADMIN_TDS_SECTION
          },
          {
            name: 'TCS Section Master',
            subName: 'nocratemaster',
            activeRule: '/masters/tds/nocratemaster',
            permission: permissions.VUE_APP_PERMISSION_ADMIN_TCS_SECTION
          },
          {
            name: 'Reasoning',
            subName: 'reasoning',
            activeRule: '/masters/tds/reasoning',
            permission: permissions.VUE_APP_PERMISSION_ADMIN_REASONING
          }
        ]
      }
      // {
      //   name: 'User Roles',
      //   subName: 'userroles',
      //   activeRule: '/userroles/role',
      //   active: true,
      //   iconPath: 'images/Lock_duotone_line.png'
      // }
    ]    
  }
]

export default sidenav
