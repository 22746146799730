<template>
  <div class="about">
    <!-- <h1>This is a login-protected page</h1> -->
  </div>
</template>
<script>
export default {
  computed: {
    user() {
      return { ...this.$oidc.userProfile, accessToken: this.$oidc.accessToken }
    },
    claims() {
      if (this.user) {
        return Object.keys(this.user).map(key => ({
          key,
          value: this.user[key]
        }))
      }
      return []
    }
  }
}
</script>
<style>
.claims {
  list-style: none;
  text-align: left;
}
</style>
