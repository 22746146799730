import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import idsrvAuth from './idsrvAuth'
import { registerMicroApps, start } from 'qiankun'
import 'nprogress/nprogress.css'
import Antd from 'ant-design-vue'
import '../index.less'
import microApps from './services/micro-app'
import NProgress from 'nprogress'

NProgress.configure({
  minimum: 0.08,
  easing: 'linear',
  positionUsing: '',
  speed: 200,
  trickle: true,
  trickleSpeed: 200,
  showSpinner: true,
  barSelector: '[role="bar"]',
  spinnerSelector: '[role="spinner"]',
  parent: 'body',
  template: '<div class="bar" style="z-index: 1032;height: 3px" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner" style=" width: 100%; height: 100%; background: rgba(255, 255, 255, 0.5); position: fixed; top: 0; padding-top: 45vh; "><div style="width: 64px;height: 64px;background-image: url(/images/loader.gif);background-repeat: no-repeat;margin: auto;"></div></div>'
})

NProgress.start()
const app = createApp(App).use(router)
function loader (loading: any) {
  console.log('loading',loading)
  if (app._instance) {
    app._instance.props.loading = loading
  }
}

idsrvAuth.startup().then(ok => {
  if (ok) {
    // a little something extra
    app.config.globalProperties.$oidc = idsrvAuth
    app.use(Antd)
    app.mount('#app')
    const apps: any = microApps.map((item: any) => {
      return {
        ...item,
        loader
      }
    })
    registerMicroApps(apps, {
      beforeLoad(app): any {
        console.log('before load app.name====>>>>>', app.name)
        NProgress.start()
      },
      beforeMount(app): any {
        console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
        NProgress.start()
      },
      afterMount(app): any {
        console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
        NProgress.done()
      },
      afterUnmount(app): any {
        console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
        NProgress.start()
      }
    })
    start()
  } else {
    console.log('Startup was not ok')
  }
})
